import React from "react";
import logo from "./resources/images/FM.png"
import {Link} from 'react-router-dom';

export const HeroBanner = ({headline, description, url_website}) => {

  return (
    <div className="hero-banner">
      <div className="hero-banner__logo">
      <Link to="https://www.flandersmake.be/en">
        <img className="hero-banner__image" src={logo} alt="Flanders Make logo" />
      </Link>
        
      </div>
      <h1 className="hero-banner__headline">{headline}</h1>
      <p className="hero-banner__description">
        {description}
      </p>
      {/* <a
        id="code-sample-link"
        href="https://www.flandersmake.be/en"
        className="button button--secondary"
      >
        Check out the Flanders Make website →
      </a> */}
    </div>
  );
};