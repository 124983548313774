import React from "react";
import { BenchmarkFeature } from "./benchmark-feature";
// import axios from 'axios';
import lpbfThumb from "./resources/images/lpbf-thumbnail.png"
import ctThumb from "./resources/images/ct-thumbnail-3.png"

export const BenchmarkFeatures = () => {
//   const URL = process.env.REACT_APP_URL
  const featuresList = [
    {
      title: "The RAISE-LPBF-Laser benchmark",
      description:
        "RAISE-LPBF is a large dataset on the effect of laser power and laser dot speed in powder bed fusion of stainless steel, monitored by on-axis 20k FPS video.",
      icon: lpbfThumb,
      id: "1",
      resourceUrl: "https://www.flandersmake.be/en"
    },
    {
        title: "Coming soon: RAISE-LPBF-Density...",
        icon: ctThumb,
        id: "2",
    },
  ];

  return (
    <div className="benchmark-features">
      <h2 className="benchmark-features__title">Datasets with perpetual competitions</h2>
      <div className="benchmark-features__grid">
        {featuresList.map((feature) => (
          <BenchmarkFeature
            key={feature.id}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
            icon={feature.icon}
          />
        ))}
      </div>
    </div>
  );
};
