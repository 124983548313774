// import { useEffect, useState } from "react";
// import tableData1 from "data/table.json";
import { useEffect, useMemo, useState } from "react";
import {TableBody} from "./TableBody";
import TableHead from "./TableHead";
import axios from "axios";
import { Loading } from "../../pages/loading";
// import "./styles/components/table.css"

export const Table = ({url, dataurl, configurl}) => {

    // console.log(configurl)

    const columns = useMemo(() => [
        { label: "Model name", accessor: "model", sortable: false},
        { label: "Submitter", accessor: "name", sortable: false},
        { label: "RMSE speed", accessor: "MSE_speed", sortable: true, sortbyOrder: "desc" },
        { label: "RMSE power", accessor: "MSE_power", sortable: true},
        { label: "RMSE density", accessor: "MSE_density", sortable: true},
        { label: "Window size", accessor: "window", sortable: true},
        { label: "Inference latency", accessor: "latency", sortable: true},
        { label: "Submission date", accessor: "date", sortable: true  },
        { label: "GitHub", accessor: "git", sortable: false },
        { label: "Paper", accessor: "paper", sortable: false  },
       ], []);

    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...tableData].sort((a, b) => {
             if (a[sortField] === null) return 1;
             if (b[sortField] === null) return -1;
             if (a[sortField] === null && b[sortField] === null) return 0;
             return (
              a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
               numeric: true,
              }) * (sortOrder === "asc" ? 1 : -1)
             );
            });
            setTableData(sorted);
           }
    };

    const [tableData, setTableData] = useState(0);
    const [found, setfound] = useState(false)
    // const [userData, setuserData] = useState("hi")
    // const URL = process.env.REACT_APP_API_COSMOS_GETDATATABLE
    // const res = axios.get(URL);
    // console.log(res)



    useEffect( () => {
      async function fetchData() {
        //   console.log(configurl.params.user)
          const dto = await axios.post(url, dataurl, configurl)
          const data = dto.data
          console.log(data)
          setTableData(data)
          setfound(true)
          
          // setTableData(getDefaultSorting(data, columns))
      }
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    //  function getDefaultSorting(defaultTableData, columns) {
    //     const sorted = [...defaultTableData].sort((a, b) => {
    //      const filterColumn = columns.filter((column) => column.sortbyOrder);
       
    //      // Merge all array objects into single object and extract accessor and sortbyOrder keys
    //      let { accessor = "id", sortbyOrder = "asc" } = Object.assign(
    //       {},
    //       ...filterColumn
    //      );
       
    //      if (a[accessor] === null) return 1;
    //      if (b[accessor] === null) return -1;
    //      if (a[accessor] === null && b[accessor] === null) return 0;
       
    //      const ascending = a[accessor]
    //       .toString()
    //       .localeCompare(b[accessor].toString(), "en", {
    //        numeric: true,
    //       });
       
    //      return sortbyOrder === "asc" ? ascending : -ascending;
    //     });
    //     return sorted;
    //    }

     

 return (
  <>
    {found && 
    <table className="table">
      <>
        <TableHead columns={columns} handleSorting={handleSorting}/>
        <TableBody columns={columns} tableData={tableData}/>
        </>
        </table>}
    {!found &&
      <div className="wrapper-loading">
    <Loading></Loading>
    </div>
    }
  </>
 );
};