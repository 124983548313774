import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const BenchmarkFeature = ({ title, description, resourceUrl, icon }) => {

    const [link_url, setlink_url] = useState("")

    useEffect(() => {
        if (resourceUrl){
            setlink_url(`/benchmark/${title}`)
        } else {
            setlink_url(`/not`)
        }
    }, [])
    
    
    

    return (
    <Link to={link_url}>
  <div
    // href={resourceUrl}
    className="raise-feature"
    target="_blank"
    rel="noopener noreferrer"
  >
    
    <h3 className="raise-feature__headline">
      <img
        width={40}
        height={40}
        className="raise-feature__icon"
        src={icon}
        alt="external link icon"
      />
      {title}
    </h3>
    <p className="raise-feature__description">{description}</p>
    
  </div>
  </Link>
)};