import React from "react";
import { BenchmarkFeatures } from "../components/benchmark-features";
import { HeroBanner } from "../components/hero-banner";
import { PageLayout } from "../components/page-layout";
import { RaiseFeatures } from "../components/raise-features";

/**
 * Home page
 * @returns 
 */
export const HomePage = () => (
  <PageLayout>
    <HeroBanner headline={"Benchmarks for Additive Manufacturing"} description = {"Datasets for training and evaluating machine learning models for additive manufacturing"}/>
    {/* <RaiseFeatures /> */}
    <BenchmarkFeatures/>
  </PageLayout>
);
