import React from "react";
import { RaiseFeature } from "./raise-feature";
import euLogo from "./resources/images/Flag_Europa_square.png";
import lpbfThumb from "./resources/images/lpbf-thumbnail.png";
import download_icon from "./resources/images/download.svg";
import results_icon from "./resources/images/results.svg";
import paper_icon from "./resources/images/paper.svg";
import submit_icon from "./resources/images/submit.svg";
import github_icon from "./resources/images/github.png";
import dataset from "./resources/images/dataset.png";
// import axios from 'axios';

export const RaiseFeatures = () => {
  const URL = process.env.REACT_APP_URL
  const featuresList = [
    /*{
      title: "What is RAISE-LPBF-Laser?",
      description:
        "RAISE-LPBF is a large dataset on the effect of laser power and laser dot speed in 316L stainless steel bulk material. Both process parameters are independently sampled for each scan line from a continuous distribution, so interactions of different parameter choices can be investigated. Process monitoring comprises on-axis 20k FPS video. The data can be used to derive statistical properties of LPBF, as well as to build anomaly detectors. RAISE-LPBF-Laser is the machine learning benchmark to reconstruct the laser parameters of the RAISE-LBPF dataset.",
      //resourceUrl: "",
      icon: lpbfThumb,
    },*/
    {
      title: "Download dataset",
      description:
        "The training data comprises 12 cylinders, the test set 4. Each cylinder has 669 fully randomized layers, each with about 84 scan lines. To each line corresponds a pair of laser parameters: speed in 𝑚𝑚∕𝑠 and power in 𝑊.",
      resourceUrl: URL + "/download",
      icon: download_icon,
    },
    {
      title: "See results",
      description:
        "Check out the results and leaderboard for this dataset.",
      resourceUrl: URL + "/public",
      icon: results_icon,
    },
    {
      title: "Example code",
      description:
        "Example code to load the data in PyTorch, and a few baseline AI models.",
      resourceUrl: "https://github.com/Flanders-Make-vzw/RAISE_LPBF_Laser_benchmark",
      icon: github_icon,
    },
    {
      title: "How to submit",
      description:
        "After you have built a predictive model on the training set, you can submit predictions for the test set to get listed on the leaderboard. Instructions on how to submit your model's predictions. (Requires login/signup.)",
      resourceUrl: URL + "/protected",
      icon: submit_icon,
    },
    {
      title: "The paper",
      description:
        "C. Blanc, A. Ahar, and K. De Grave. \"Reference dataset and benchmark for reconstructing laser parameters from on-axis video in powder bed fusion of bulk stainless steel.\" Additive Manufacturing Letters (2023): 100161.",
      resourceUrl: "https://doi.org/10.1016/j.addlet.2023.100161",
      icon: paper_icon,
    },
    {
      title: "Acknowledgements",
      description:
        "The CoE RAISE project has received funding from the European Union’s Horizon 2020 – Research and Innovation Framework Programme H2020-INFRAEDI-2019-1 under grant agreement no. 951733.",
      resourceUrl: "https://www.coe-raise.eu/",
      icon: euLogo,
    },
  ];

  // const [userData, setuserData] = useState("hi")

  // const getUserData = () => {
  //   axios.get('https://benchmark-function-fm.azurewebsites.net/api/HttpTrigger2')
  //   .then((res) => {
  //       setuserData(res)
  //   })
  // }

  // useEffect(() => {
  //   getUserData();
  // }, [])
  

  return (
    <div className="raise-features">
      <h2 className="raise-features__title">Predict laser properties</h2>
      <div id="raise-intro">
           <div>
              <h3 className="raise-feature__headline">
                <img
                  width={40}
                  height={40}
                  className="raise-feature__icon"
                  src={lpbfThumb}
                  alt="external link icon"
                />
                What is RAISE-LPBF-Laser?
              </h3>
              <div className="raise-features__grid">
                <p className="raise-feature__description">RAISE-LPBF is a large dataset on the effect of laser power and laser dot speed in 316L stainless steel bulk material. Both process parameters are independently sampled for each scan line from a continuous distribution, so interactions of different parameter choices can be investigated. The process is monitored by an on-axis camera at 20,000 frames per second. The data can be used to derive statistical properties of LPBF, as well as to build anomaly detectors. RAISE-LPBF-Laser is the machine learning benchmark to reconstruct the laser parameters of the RAISE-LBPF dataset.</p>
                <img width={"100%"} src={dataset} style={{margin:0}}></img>
              </div>
          </div>
      </div>
      <div className="raise-features__grid">
        {featuresList.map((feature) => (
          <RaiseFeature
            key={feature.resourceUrl}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
            icon={feature.icon}
          />
        ))}
      </div>

      {/* <p>{userData.data}</p> */}
      
    </div>
  );
};
