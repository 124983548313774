import React from 'react'
import { PageLayout } from "../components/page-layout";

/**
 * Can update the submissions
 * @returns
 */
export const Update = () => {
  return (
    <PageLayout>
        <div className="content-layout">
        <h1 id="page-title" className="content__title">
          To be constructed...
        </h1>
        </div></PageLayout>
  )
}