import React from 'react'
import "./App.css"
import { Route, Routes } from "react-router-dom";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { ProtectedPage } from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";
import { Update } from "./pages/update";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from './components/authentication-guard';
import { SpecificSubmission } from './pages/specific-submission';
import { SpecificBenchmark } from './pages/specific-benchmark';
import { DownloadPage } from './pages/download';

export const App = () => {

  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route path="/public" element={<PublicPage />} />
      <Route
        path="/protected"
        element={<AuthenticationGuard component={ProtectedPage} />}
      />
      <Route
        path="/update"
        element={<AuthenticationGuard component={Update} />}
      />
      <Route
        path="/home"
        element={<HomePage/>}
      />
      <Route path="/items/:id" element={<SpecificSubmission />} />
      <Route path="/download" element={<DownloadPage />} />
      <Route path="/benchmark/:id" element={<SpecificBenchmark />} />
      {/* <Route
        path="/admin"
        element={<AuthenticationGuard component={AdminPage} />}
      /> */}
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};