import React from "react";
import { PageFooterHyperlink } from "./page-footer-hyperlink";

export const PageFooter = () => {
  const resourceList = [
    {
      path: "https://www.flandersmake.be/en/research/hot-topics/additive-manufacturing",
      label: "Additive manufacturing research",
    },
    {
      path: "https://www.flandersmake.be/en/testing-validation/production-optimisation/additive-manufacturing-pilot-machines",
      label: "Additive manufacturing pilot machines",
    },
    {
      path: "https://www.coe-raise.eu/",
      label: "Center of Excellence RAISE",
    },
  ];

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__message">
            <p className="page-footer-message__headline">
              <span>Brought to you by&nbsp;</span>
              <PageFooterHyperlink path="https://www.flandersmake.be/en/">
                Flanders Make
              </PageFooterHyperlink>
            </p>
          </div>
          <div className="page-footer-info__resource-list">
            {resourceList.map((resource) => (
              <div
                key={resource.path}
                className="page-footer-info__resource-list-item"
              >
                <PageFooterHyperlink path={resource.path}>
                  {resource.label}
                </PageFooterHyperlink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};
