import React from "react";
import LoadingIcons from 'react-loading-icons'



/**
 * Page loading
 * @returns
 */
export const Loading = () => {
  return (
      <LoadingIcons.ThreeDots stroke="#635dff" speed={.75}/>
  );
};