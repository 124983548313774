import React from "react";
import { PageLayout } from "../components/page-layout";
import { SubmitForm } from "../components/submit-form";
// import { Table } from "../components/table/Table";
// import { useAuth0 } from "@auth0/auth0-react";
// import template from "../components/resources/images/template.png"

/**
 * Page of the submissions which can only be seen when logged in (authenticated)
 * @returns 
 */
export const ProtectedPage = () => {

  // const { user } = useAuth0();
  // const URL = process.env.REACT_APP_API_COSMOS_OWNDATATABLE

  // const config = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //     'Access-Control-Allow-Methods': '*'
  //   },
  //   params: {
  //      user: JSON.parse(JSON.stringify(user, null, 2)).sub
  //   }
  // }
  // const data = {
  //    data: 'whatever'
  // }

  return (
    <PageLayout>
      <div className="content-layout">
        <div className="content__body">
          <h2 id="page-h2">How to submit your predictions?</h2>
          <p id="page-description">
            <span>
            One laser parameter tuple must be provided per scan line in the test set. Only randmized layers (no nominal layers) are included in the test set.
            The predictions are submitted in the form of a comma separated values (CSV) file, see the short template for a few example lines. Make sure that a prediction is made for all layers and all scanlines of the test set objects with the correct field names (object, layer, scanline, speed and power). Otherwise your submission will be rejected.
            </span>
            <code>
	    object,layer,scanline,speed,power<br/>
C029,31,0,842.3909,178.09139<br/>
C029,31,1,870.1099,120.64635<br/>
C029,31,2,1112.6395,136.55481<br/>
C029,31,3,789.24691,132.84517<br/>
C029,31,4,797.4128,128.45643<br/>
...<br/>

            
            </code>
            <span>
            We will compare your predictions with the actual laser parameters and
            your predictions will immediately appear in <a href="/public">the results table</a>, where
            models are ranked based on the RMSE of either power, speed, or linear
            power density (power/speed, which is proportional to volumetric energy density). 
            Submitted models should be
            published eventually as source code, a research paper, or
            ideally both.
            There is an obvious side channel for speed: the number
            of camera frames in a scan line provides direct information
            about speed, and especially so when also taking the known
            geometry into account. While we could eliminate the side-channel
            by slicing up the test set in fixed-length chunks and
            shuffling them, we prefer to keep open the flexibility for the
            community to develop models with different window sizes,
            or no window size limitation at all. We just ask not to use the
            side channel.
            </span>
            <span>
            You can submit new results for RAISE-LPBF-Laser at most once per day (24h sliding window), to avoid cluttering the results table and to avoid leaking the true labels through brute forcing.
            </span>
            <div>
              <a href="https://benchmarkblob.blob.core.windows.net/examplecsv/template.csv">
              <button type = "submit" id= "submitBtn" className = "submitBtn"> Download the (shortened) template for submission</button>
              </a>
            </div>
          </p>
          <SubmitForm></SubmitForm>
        </div>
      </div>
    </PageLayout>
  );
};
