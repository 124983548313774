import React from "react";
import { HeroBanner } from "../components/hero-banner";
import { PageLayout } from "../components/page-layout";
import { RaiseFeatures } from "../components/raise-features";
import { useParams } from 'react-router-dom';

/**
 * Home page
 * @returns 
 */
export const SpecificBenchmark = () => {

    const param = useParams()
    console.log(param.id)

    const featuresListRAISE = [
        {
          title: "The idea",
          description:
          "RAISE-LPBF is a large dataset on the effect of laser power and laser dot speed in powder bed fusion (LPBF) of 316L stainless steel bulk material, monitored by on-axis 20k FPS video.",
          resourceUrl: "",
          icon: "https://cdn-icons-png.flaticon.com/512/4151/4151213.png",
        },
        {
          title: "Download dataset",
          description:
            "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
          resourceUrl: "https://uftp.fz-juelich.de:9112/UFTP_Auth/rest/access/JUDAC/dc4eef36-1929-41f6-9eb9-c11417be1dcf",
          icon: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Download_alt_font_awesome.svg",
        },
        {
          title: "How to submit",
          description:
            "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.",
          resourceUrl: URL + "/protected",
          icon: "https://sunrust.org/wiki/images/5/56/Check_icon.png",
        },
        {
          title: "See results",
          description:
            "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
          resourceUrl: URL + "/public",
          icon: "https://cdn-icons-png.flaticon.com/512/992/992848.png",
        },
      ];

    return (
        <PageLayout>
            <HeroBanner headline={param.id} description={"A large dataset on the effect of laser power and laser dot speed in powder bed fusion (LPBF) of 316L stainless steel bulk material."}/>
            <RaiseFeatures featuresList={featuresListRAISE}/>
        </PageLayout>
    );
}

    

  
