
import React from "react";

export const RaiseFeature = ({ title, description, resourceUrl, icon }) => (
  <a
    href={resourceUrl}
    className="raise-feature"
    rel="noopener noreferrer"
  >
    <h3 className="raise-feature__headline">
      <img
        width={40}
        height={40}
        className="raise-feature__icon"
        src={icon}
        alt="external link icon"
      />
      {title}
    </h3>
    <p className="raise-feature__description">{description}</p>
  </a>
);