import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import CanvasJSReact from '@canvasjs/react-charts';
import {Table} from "../components/table/Table";
import axios from "axios";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const PublicPage = () => {

  const [dictacc, setdictAcc] = useState([])
  const [dictmean, setdictMean] = useState([])
  const [options, setoptions] = useState({})
  const URL = process.env.REACT_APP_API_COSMOS_GETDATATABLE

  /**
   * Fetching the table data and save the dicts everytime the URL changes (never)
   */
   useEffect( () => {
    async function fetchData() {
      try {
      
        const dto = await axios.post(URL)
        const data = dto.data
        let dicts = setDataPoints(data)
        const accDict = dicts[0]
        const meanDict = dicts[1]
        console.log("dict" + accDict)
        setdictAcc(accDict);
        setdictMean(meanDict);
      } catch (err) {
        console.log("error")
      }
    }
    fetchData();
  }, [URL])

  /**
   * Changing the settings when the dicts change (when a submission is done or the data is fetched for the first time)
   */
  useEffect( () => {
    async function set() {
      try {
        optionsSetter(dictacc, dictmean)
      } catch (err) {
        console.log("error")
      }
    }
    set();
  }, [dictacc, dictmean])
  
  /**
   * To get the right parts of the table to be displayed on the graph (accuracy, mean and date)
   * @param {*} userData 
   * @returns 
   */
 function setDataPoints(userData) {
  if (userData[0]){

    var dict_temp = [];

    for (let i = 0; i < userData.length; i++) {
      dict_temp.push({x: new Date(userData[i].date.substring(0,4), userData[i].date.substring(5,7) - 1, userData[i].date.substring(8,10)), y: userData[i].mean_absolute_error_power})
    }
    dict_temp = dict_temp.sort(function(a,b) {
      return b.x - a.x
    });
    var dict_temp_mean = [];
    for (let i = 0; i < userData.length; i++) {
      dict_temp_mean.push({x: new Date(userData[i].date.substring(0,4), userData[i].date.substring(5,7) - 1, userData[i].date.substring(8,10)), y: userData[i].mean_absolute_error_speed})
    }
    dict_temp_mean = dict_temp_mean.sort(function(a,b) {
      return b.x - a.x
    });

    return [dict_temp, dict_temp_mean];
  }
 }

  /**
   * Setting the options of the graph
   * @param {*} dictSetterAcc 
   * @param {*} dictSetterMean 
   */
   function optionsSetter(dictSetterAcc, dictSetterMean) {
    setoptions({
      
      animationEnabled: true,
      exportEnabled: true,
      theme: "light2",
      title:{
        text: "RAISE"
      },
      axisY: {
        title: "RMSE power",
        suffix: "",
        titleFontColor: "#6D78AD",
        lineColor: "#6D78AD",
        labelFontColor: "#6D78AD",
        tickColor: "#6D78AD"
      },
      axisY2: {
        title: "RMSE speed",
        suffix: "",
        titleFontColor: "#51CDA0",
        lineColor: "#51CDA0",
        labelFontColor: "#51CDA0",
        tickColor: "#51CDA0"
      },
      axisX: {
        title: "Submissions",
        labelFormatter: function(e){
          return e.value.toLocaleDateString()
        },
      },
      legend: {
        cursor:"pointer",
        verticalAlign: "top",
        fontSize: 18,
        fontColor: "dimGrey"
      },
      data: [{
        name: "RMSE power",
        type: "line",
        toolTipContent: "Score: {y}",
        dataPoints: dictSetterMean,
        showInLegend: true,
      },
      {
        name: "RMSE speed",
        type: "line",
        axisYType: "secondary",
        toolTipContent: "Score: {y}",
        dataPoints: dictSetterAcc ,
        showInLegend: true,
      }]})
      
  }

  const config = {
    headers: {
      // 'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*'
    },
  }

  

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Results for RAISE-LPBF-Laser
        </h1>
        <div className="content__body">
        <p>This make take a few seconds to load.</p>
        </div>
        <div className="container">
            <div className="container_raise">
                <div className="container_box">
                  <CanvasJSChart options = {options} />
                </div>
            </div>
      </div>
      <h1 id="page-title" className="content__title">
          Table of all results
        </h1>
      </div>
      <Table url={URL} configurl={config}/>
    </PageLayout>
  );
};
