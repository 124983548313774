import out from "../resources/images/out.png"
import git from "../resources/images/github.png"
import { Link } from "react-router-dom";
import { useState } from "react";

export const TableBody = ({ columns, tableData }) => {


    return (
        
     <tbody>
        {tableData !== 0 && tableData.map((data, index) =>  {
          
       return (
        
        <tr key={data.id}>
          
          <td><Link to={`/items/${data.id}`}>{index + 1}</Link></td>
         {columns.map(({ accessor }) => {
          if (accessor === "git") {
            const tData = data[accessor] ? data[accessor] : "";
            if (data[accessor]){
              return <td className="extrapaperstyling" key={accessor}><a href={tData}>
            <img src={git} width={20} height={20} alt="link to paper"/>
            </a></td>;
            } else {
              return <td className="extrapaperstyling" key={accessor}>{tData}</td>;
            }

            
          } else if (accessor === "paper"){
            const tData = data[accessor] ? data[accessor] : "";
            // const paper = data[accessor] ? "paper" : "——";
        //     return <img key={accessor} className="paperlink"
        //     width={30}
        //     height={30}
        //     src={"../src/components/resources/images/out.png"}
        //     alt="external link icon"

        //   />;
            if (data[accessor]){
              return <td className="extrapaperstyling" key={accessor}><a href={tData}>
            <img src={out} width={20} height={20} alt="link to paper"/>
            </a></td>;
            } else {
              return <td className="extrapaperstyling" key={accessor}>{tData}</td>;
            }
          
          } else if (accessor === "date"){
            const tData = data[accessor] ? data[accessor] : "——";
            return <td key={accessor}>{tData.slice(0,10)}</td>;

          } else {
            if (data[accessor] === 0){
              return <td key={accessor}>{0}</td>;
            } else {
              const tData = data[accessor] ? data[accessor] : "——";
              return <td key={accessor}>{tData}</td>;
            }
            

          }

         })}
         <td id="hover-styling">
         </td>
        </tr>
       );
      })}
     </tbody>
    );
   };
//<Link to={`/items/${data.id}`}>...</Link>
