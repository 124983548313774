import React from "react";
import { PageLayout } from "../components/page-layout";
// import { useAuth0 } from "@auth0/auth0-react";
import structureHDF5 from "../components/resources/images/structureHDF5.png"
import table1 from "../components/resources/images/table1.png"
// import { RaiseFeature } from "../components/raise-feature";

/**
 * Page of the submissions which can only be seen when logged in (authenticated)
 * @returns 
 */
export const DownloadPage = () => {

  // const { user } = useAuth0();
  return (
    <PageLayout>
      <div className="content-layout">
        <div className="content__body">
          <h2 id="page-h2">RAISE-LPBF-Laser data</h2>
          <p id="page-description">
            <span>
            The training data comprises 12 cylinders. Each cylinder has 669 randomized layers, and a number of other layers, see the table below.
            There are 84 bulk scan lines in a layer on average.
            (The contour scans are omitted.)

            Each line is characterised by a pair of laser parameters:
            speed in 𝑚𝑚∕𝑠 and power in 𝑊.
            </span>
     <div className="buttonContainer">
            <a href="https://uftp.fz-juelich.de:9112/UFTP_Auth/rest/access/JUDAC/0a4013b9-d138-4085-a03b-2b91a1e96cb9/RAISE_LPBF_train_C027_layer0202.hdf5" className="downloadBtn">
            <button type = "submit" id= "submitBtn" className = "submitBtn"> Download a small sample (one layer, one object, 85MB)</button>
            </a>
            <a href="https://uftp.fz-juelich.de:9112/UFTP_Auth/rest/access/JUDAC/0a4013b9-d138-4085-a03b-2b91a1e96cb9/RAISE_LPBF_train.hdf5" className="downloadBtn">
            <button type = "submit" id= "submitBtn" className = "submitBtn"> Download the training data (784GB)</button>
            </a>
    </div>
            <span>
            The test set contains 4 cylinders. To avoid confusion, only the layers that are to be predicted (i.e., the randomized ones) are included. The ground truth labels are omitted from the test set.
            </span>
            <a href="https://uftp.fz-juelich.de:9112/UFTP_Auth/rest/access/JUDAC/0a4013b9-d138-4085-a03b-2b91a1e96cb9/RAISE_LPBF_test.hdf5">
            <button type = "submit" id= "submitBtn" className = "submitBtn"> Download the test set (249GB)</button>
            </a>
            <img alt="..." style={{margin:30}} width={"50%"} src={table1}></img>
            <span>
            We provide the data as files in HDF5 format, one for
            training and one for testing. The size on disk of the dataset
            is around 1TB, using HDF5-native chunked lossless gzip
            compression. Frames are compressed individually, so they
            can be accessed randomly without excessive overhead. The
            structure of the training set is illustrated in the figure below. The
            table scan_line_index provides for each camera frame which of the scan lines the frame belongs to. The scan lines are
            numbered sequentially as they are printed, restarting from 0
            in each layer of each object.
            </span>
            <span>
            For more details, read the paper: 
            <a href="https://doi.org/10.1016/j.addlet.2023.100161">Blanc, Cyril, Ayyoub Ahar, and Kurt De Grave. "Reference dataset and benchmark for reconstructing laser parameters from on-axis video in powder bed fusion of bulk stainless steel." <i>Additive Manufacturing Letters</i> (2023): 100161.</a>
            </span>
          </p>
          <img alt="..." style={{margin:30}} width={"80%"} src={structureHDF5}></img>
          <h2 id="page-h2">License</h2>
            <span><p>The dataset is licensed as <a href="https://creativecommons.org/licenses/by-nc/4.0/">CC BY-NC 4.0</a>.  
            If you want to use the dataset for commercial purposes, please let us know.</p>
            <p>The ground truth for the test set is not released but used solely to benchmark predictions submitted to this website.</p>
            <p>The journal paper (including a couple of images reused on this website) is published by Elsevier as Gold open access and may not be redistributed without Elsevier's permission (but you can share a link and everyone can read it). </p>
            </span>

          <h2 id="page-h2">Checksums</h2>
          <p>
            <span>
            Verify your downloads:
            <table class="table">
            <thead>
            <tr><th>File</th><th>SHA256</th></tr></thead>
            <tbody>
            <tr><td>RAISE_LPBF_train_C027_layer0202.hdf5</td><td>2bfd9cfb294ab442e377ef3212c3bf7d57ec07b4da65b06933a551a11f058f13</td></tr>
            <tr><td>RAISE_LPBF_train.hdf5</td> <td>fddaa352fc3c24d00f50c7772ee177888892ba609d05362cd8da9fffc2955a49</td></tr>
            <tr><td>RAISE_LPBF_test.hdf5</td><td>8096028bc317f01455acdd9ea1fd5d96404c5b8dccad476a5ebbc0e6d35e0fab</td></tr>
            </tbody>
            </table>

            </span>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};
