import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import { Table } from "../components/table/Table";

/**
 * Profile page which can only be seen when authenticated
 * @returns 
 */
export const ProfilePage = ({navigation}) => {
  const { user } = useAuth0();
  
  if (!user) {
    return null;
  }
  const URL = process.env.REACT_APP_API_COSMOS_OWNDATATABLE

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*'
    },
    params: {
       user: JSON.parse(JSON.stringify(user, null, 2)).sub
    }
  }
  const data = {
     data: 'whatever'
  }


  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Profile Page
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              You can use the <strong>ID Token</strong> to get the profile
              information of an authenticated user.
            </span>
            <span>
              <strong>Only authenticated users can access this page.</strong>
            </span>
          </p>
          <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2 className="profile__title">{user.name}</h2>
                <span className="profile__description">{user.email}</span>
              </div>
            </div>
            <div className="profile__details">
              <CodeSnippet
                title="Decoded ID Token"
                code={JSON.stringify(user, null, 2)}
              />
            </div>
            <div style={{margin:30}}></div>
            <h1 id="page-title" className="content__title">
          Already submitted results
        </h1>
            <Table url={URL} dataurl={data} configurl={config}/>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};