import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { PageLayout } from '../components/page-layout'
import axios from "axios";

/**
 * Will in the future give more information about a specific submission
 * @returns 
 */
export const SpecificSubmission = () => {

  const param = useParams();
  console.log(param)
  const URL = process.env.REACT_APP_API_COSMOS_BYID
  const [TableData, setTableData] = useState([])

  /**
   * Fetch data of specific submission by id whenever the id changes
   */
  useEffect( () => {
    async function fetchData() {
        const dto = await axios.post(URL, dataurl, config)
        const data = dto.data
        console.log("check")
        console.log(data)
        setTableData(data)
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*'
      },
        params: {
           id: param.id
        }
      }
    const dataurl = {
        data: 'whatever'
     }
    fetchData();
  }, [URL, param])

  const update = () => {
    console.log("update")
  }

  return (
    <PageLayout><div className="content-layout">
    <h1 id="page-title" className="content__title">
      Detailed description of submission
    </h1>
    {TableData[0] &&
    <div className="content__body">
        
          <p id="page-description">Name: {TableData[0].name}</p>
          <p id="page-description">Email: {TableData[0].email}</p>
          <p id="page-description">Date: {TableData[0].date}</p>
          <p id="page-description">Git: {TableData[0].git}</p>
          <p id="page-description">paper: {TableData[0].paper}</p>
          <Link to="/update">
          <button type = "submit" id= "submitBtn" className = "submitBtn" onClick={update}>update</button>
          </Link>
        
    </div>
    }
    </div></PageLayout>
  )
}