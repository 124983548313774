import React, { useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import * as Papa from 'papaparse';
import {BlobServiceClient, ContainerClient} from '@azure/storage-blob'
import { Loading } from "../pages/loading";
import { useNavigate } from "react-router-dom";


/**
 * Component which consists of the form for submission
 * @returns 
 */
export const SubmitForm = () => {

  // const navigation = useNavigation();
  const navigate = useNavigate();

  const { user } = useAuth0();
  const [inputs, setInputs] = useState({});
  const allowedExtensions = ["csv"];
  const [dataCSV, setDataCSV] = useState([]);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);
  const [validfile, setvalid] = useState(false);
  const [loading, setloading] = useState(false)
  const URL = process.env.REACT_APP_API_COSMOS_ADDSUBMISSIONS
  const sasToken = process.env.REACT_APP_API_SAS_KEY1_UPLOAD
  const head = ['object','layer','scanline','speed','power']
  const numberLayers = 233462
  
  // const URL = 'http://localhost:7071/api/AddSubmission'


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const email = event.target.email;
    const url_git = event.target.url_git;
    const url_paper = event.target.url_paper;
    const model = event.model;
    const window = event.target.window;
    const latency = event.latency;
    setInputs(values => ({...values, [name]: value, [email]: email, [url_git]: url_git, [url_paper]: url_paper, [model]: model, [window]: window, [latency]:latency}))
    console.log(inputs)
  }

  const processCSV = () => {
    file.open('r');
    file.encoding = 'utf-8';
    var data = file.read().split('/\r\n|\n/'); // split by lines
    file.close();
    for (var row in data) data[row].split(','); // split all lines by comas
    
    alert(data); // here is your 2d array
  }

  

  /**
   * Saves file if it exists and has the correct extension (csv)
   * @param {*} e 
   * @returns 
   */
  const handleFileChange = (e) => {
    setError("");
    if (e.target.files.length) {
        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
            setError("Please input a csv file");
            alert("Please input a csv file")
            return;
        }
        
        const newFilename = JSON.parse(JSON.stringify(user, null, 2)).sub + '.csv'
        console.log(newFilename)
        const newNamedFile = renameFile(inputFile, newFilename)
        console.log(newNamedFile.name)

        setFile(newNamedFile);
        
        var reader = new FileReader();
        reader.onload = (frevent)=>{
          let data = Papa.parse(frevent.target.result);
          // Check head of csv
          if (JSON.stringify(data.data[0]) === JSON.stringify(head)){
            if (data.data.length == numberLayers){
              setvalid(true)
            } else {
              setvalid(false)
              alert("The number of layers is not correct")
            }
          } else {
            alert("The head of the file is not correct")
            setvalid(false)

          }
        }
        reader.readAsText(e.target.files[0]);
    }
};

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
  });
}

async function CheckifBlobExistsAndUploadToBlob() {

  const containerName = 'uploadscsv'
let storageAccountName = 'benchmarkblob'
// const blobService = new BlobServiceClient("https://benchmarkblob.blob.core.windows.net/uploadscsv?sp=c&st=2023-04-26T08:30:53Z&se=2023-04-26T16:30:53Z&spr=https&sv=2021-12-02&sr=c&sig=pd62rmHq1pM2%2F3KXapHDRdFDqqLHjPLhcbUEPYGUn04%3D")
const blobService = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net/?${sasToken}`);
const containerClient = blobService.getContainerClient(containerName);
// containerClient.createIfNotExists({access: 'container',});
const blobClient = containerClient.getBlockBlobClient(file.name);
// blobClient.setTags("1");
const options = {blobHTTPHeaders: {blobContentType: file.type}};
// blobClient.uploadBrowserData(file, options)

  // page size - artificially low as example
  const maxPageSize = 999;

  let i = 1;
  let marker;

  // some options for filtering list
  const listOptions = {
    includeMetadata: false,
    includeSnapshots: false,
    includeTags: false,
    includeVersions: false,
    prefix: ''
  };

  let iterator = containerClient.listBlobsFlat(listOptions).byPage({ maxPageSize });
  let response = (await iterator.next()).value;

  // Prints blob names
  for (const blob of response.segment.blobItems) {
    // console.log(`Flat listing: ${i++}: ${blob.name}`);
    if (blob.name === file.name){
      const one_hour=1000*60*60*24;
      const one_day=one_hour*24;
      // console.log(`blob listing: ${i++}: ${blob.properties.createdOn.getTime()}`);
      // console.log(`file listing: ${i++}: ${file.lastModified}`);
      const currentDate = new Date(); 
      const timestamp_previous = blob.properties.createdOn.getTime()
      const timestamp_current = currentDate.getTime()
      var difference_ms = timestamp_previous - timestamp_current;
      // const timestamp = currentDate.getTime();
      // console.log("now:" + difference_ms)
      // Convert back to days and return
      const day_differences = Math.abs(Math.floor(difference_ms/one_day));

      if (day_differences < 1){
        alert("You have uploaded less than 24h ago. Please wait the appropriate time to submit again.")
        return true;
      }
    }

  }
  console.log("NOT the same")


  console.log("upload")

  setloading(true)
  await blobClient.uploadBrowserData(file, options)
  alert("Submitted correctly")
  

  return false;
}



    

  


  // console.log(blobService.getProperties());
  // let iterator = containerClient.listBlobsFlat(listOptions);
  // let response = (iterator.next()).value;
  // console.log(iterator)
   // Prints blob names
  //  let i = 1;
  // for (const blob of response.segment.blobItems) {
  //   console.log(`Flat listing: ${i++}: ${blob.name}`);
  // }


  // console.log(containerClient.findBlobsByTags('1'));
  // const i = 1;
  // for (const blob of containerClient.listBlobsByHierarchy) {
  //   console.log(`Blob ${i++}: ${blob.name}`);
  // };

  // if (blob != null) {
  //   console.log("It already exists")
  // }
  // else {
  //   console.log("upload")
  //   blobClient.uploadBrowserData(file, options)
  // }

/**
 * Parses csv file if one is supplied
 * @returns 
 */
const handleParse = () => {
  if (!file) {
    setError("Enter a valid file");
    alert("Please input a file");
    return;
  }


};

/**
 * Gives notice when submitted
 * @param {} event 
 */
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted");
  }

  /**
   * If everything is entered correctly, then submission gets added to the database
   * @returns 
   */
  const validateDataAndAddToCosmosDB = async () => {
    var validationSucceeded = true;

    if(!inputs.email || !inputs.username || !inputs.model){
      alert("You have not filled in everything");
      validationSucceeded = false;
      return;
    }

    if (inputs.model.length > 30){
      alert("Model name is too long");
      validationSucceeded = false;
      return;
    }

    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if(validationSucceeded && !inputs.email.match(mailformat)){
      alert("You have entered an invalid email address!");
      validationSucceeded = false;
      return;
    }    

    if(isNaN(inputs.window)){
      alert("You should enter a number for the window size");
      validationSucceeded = false;
      return;
    }

    if(isNaN(inputs.latency)){
      alert("You should enter a number for the inference latency");
      validationSucceeded = false;
      return;
    }

    handleParse();

    if (validationSucceeded && validfile){
      postUserData();
    }
  }

  /**
   * Send user data to COSMOS DB
   */
  const postUserData = () => {

    console.log("data")
    // const body = new FormData()
    // body.append('file', file)
    const config = {
      headers: {
        'Content-Type': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*'
      },
      params: {
         name: inputs.username,
         email: inputs.email,
         url_git: inputs.url_git,
         url_paper: inputs.url_paper,
         user: JSON.parse(JSON.stringify(user, null, 2)).sub,
         date: new Date(),
         model: inputs.model,
         window: inputs.window,
         latency: inputs.latency,
        //  body: body
      }
    }

    const data = {
       data: "nothing"
    }

    // axios.post(URL, data, config)

    
    

    CheckifBlobExistsAndUploadToBlob().then(canUpload => {
      console.log(canUpload)
      if (!canUpload){
        console.log("inside if")
        axios.post(URL, data, config)
        .then((response) => response.status === 200 ? navigate("/profile").then(setloading(false)) : alert("No, submission did not work"))
        .catch((error) => console.log(error));
        
        
      } 
      else {
        console.log("inside else")
        // alert("You already submitted");
      }


    });

    


    // axios.post(URL, formData, config)
  }

  return (
    <>
    {loading && <Loading></Loading>}
    {!loading &&
    <form onSubmit={handleSubmit}>
      <div className = "form-box">
        <h5 className = "form-step"> Predictions submission form</h5>
        <div className = "field1">
          <label>The name of the submitting group or individual (will be public):</label>
          <input 
            type="text" 
            name="username" 
            value={inputs.username || ""} 
            onChange={handleChange}
          /></div>
          <div className = "field1">

          <label>The name of your trained model (max 30 symbols):</label>
          <input 
            type="text" 
            name="model" 
            value={inputs.model || ""} 
            onChange={handleChange}
          /></div>
          <div className = "field1">

          <label>Your email (this will not be shared):</label>
            <input 
              type="email" 
              name="email" 
              value={inputs.email || ""} 
              onChange={handleChange}
            />
            </div>
          <div className = "field1">

          <label>The window size (how many frames are used per prediction):</label>
          <input 
            type="text" 
            name="window" 
            value={inputs.window || ""} 
            onChange={handleChange}
          /></div>
          <div className = "field1">

          <label>An indication of the inference latency (in ms):</label>
            <input 
              type="text" 
              name="latency" 
              value={inputs.latency || ""} 
              onChange={handleChange}
            />
            </div>
          <div className = "field1">
            
            <label>The source code (github/gitlab/bitbucket URL) (if available):</label>
            <input 
              type="text" 
              name="url_git" 
              value={inputs.url_git || ""} 
              onChange={handleChange}
            />

          </div>
          <div className = "field1">
            
            <label>URL of the paper (you can add this later):</label>
            <input
              type="text" 
              name="url_paper" 
              value={inputs.url_paper || ""} 
              onChange={handleChange}
            />
          </div>
          <div className = "field1">
          <label>Drop a csv with the predicted labels, matching the template format but much longer:</label>
            <input
            type="file"
            name="file"
            style={{ display: "block", margin: "10px auto" }}
            onChange={handleFileChange}
            accept=".csv" 
          />
          </div>
        <button type = "submit" id= "submitBtn" className = "submitBtn" onClick={validateDataAndAddToCosmosDB}> submit</button>
        <div style={{ marginTop: "3rem" }}>
                {error ? error : dataCSV.map((col,
                  idx) => <div key={idx}>{col.Series_reference}</div>)}
            </div>
        </div>
    </form>
    }
    </>
    
  )
  };
